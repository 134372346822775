<script>
import View from "@/views/View";
import LoadSections from "@/mixins/load-sections";
import sectionsJson from "@/sections.json";

const sections = sectionsJson.filter(sec => !sec.disabled || location.port).map(sec => sec["component"]);

export default {
  name: "Home",
  metaInfo: { title: "Home" },
  extends: View,
  mixins: [LoadSections(sections)],
  props: {
    id: {
      type: String,
      default: "home"
    }
  }
};
</script>
