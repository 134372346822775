// Utilities
import { camelCase, upperFirst } from "lodash";
import sectionsJson from "@/sections.json";
const SECTIONS = sectionsJson.filter(sec => !sec.disabled || location.port);

export default function() {
  let sections = [];
  SECTIONS.forEach(section => {
    sections.push(section.component);
  });
  return {
    name: "LoadSections",

    // Iterates the provide list of components
    // and returns a function that returns a
    // Promise.
    components: sections.reduce((acc, cur) => {
      const name = upperFirst(camelCase(cur));

      acc[`Section${name}`] = () => import(`@/views/sections/${name}.vue`);

      return acc;
    }, {}),

    data: () => ({ sections })
  };
}
