<template>
  <div>
    <v-dialog v-model="popup" :width="pdf && video ? '75vw' : '95vw'">
      <div class="hide-overflow">
        <v-card>
          <v-card-title
            ref="popupTitle"
            class="text-h5 white--text"
            style="background-color: rgb(0, 119, 182);"
          >
            <span id="popup-title" v-html="popupTitle"></span>
            <v-spacer></v-spacer>
            <v-btn icon dark class="ma-2" @click="closePopup()">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <!-- <v-img style="width: 100%;" :src="`img/${image}`"></v-img> -->
          <iframe
            v-if="pdf && !video"
            :width="`${popupWidth}px`"
            :height="`${popupHeight}px`"
            :src="pdf"
          ></iframe>
          <div v-if="pdf && video">
            <vue-plyr>
              <video controls crossorigin playsinline>
                <source :src="pdf" type="video/mp4" />
              </video>
            </vue-plyr>
          </div>
        </v-card>
      </div>
    </v-dialog>

    <section :id="id">
      <v-row no-gutters>
        <v-col cols="12">
          <component
            :is="`section-${section}`"
            v-for="section in sections"
            :key="section"
          />
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import popups from "@/popups.json";
export default {
  name: "BaseView",

  components: {},

  props: {
    id: {
      type: String,
      default: "view"
    }
  },

  data: () => ({
    sections: [],
    popup: false,
    image: null,
    pdf: null,
    petition: false,
    popupTitle: "",
    offsetHeight: 0,
    counter: 0,
    video: false
  }),
  computed: {
    popupHeight() {
      return (
        window.innerHeight * 0.95 -
        this.offsetHeight +
        (this.counter - this.counter)
      );
    },
    popupWidth() {
      return window.innerWidth * 0.95 + (this.counter - this.counter);
    }
  },
  methods: {
    openImagePopup(img) {
      this.image = img;
      this.video = false;
      this.popup = true;
    },
    setOffsetHeight() {
      const el = this.$refs.popupTitle;
      this.offsetHeight = el ? el.offsetHeight : 0;
    },
    openPdfPopup(link, context) {
      if (link === "petition") {
        this.openPetitionPopup();
        return false;
      } else if (!link) {
        return false;
      }
      if (this.$vuetify.breakpoint.name === "xs") {
        const POPUP = popups[link];
        window.open(POPUP.link, "_blank");
      } else {
        const POPUP = popups[link];
        if (POPUP.linkout) {
          window.open(POPUP.link, "_blank");
        } else {
          this.pdf = POPUP.link;
          this.video = !!POPUP.video;
          this.popupTitle = POPUP.title;
          this.popup = true;
          const path = this.$route.path;
          let routePath = `${path}#${context}?popup=${link}`;
          history.pushState({}, null, routePath);
          this.$ga.page(routePath);
          setTimeout(() => {
            this.setOffsetHeight();
          }, 500);
        }
      }
    },
    openPetitionPopup() {
      this.petition = true;
      this.popupTitle = "Sign Our Petition";
      this.popup = true;
      const path = this.$route.path + this.$route.hash.split("?popup=")[0];
      let routePath = `${path}?popup=petition`;
      history.pushState({}, null, routePath);
      this.$ga.page(routePath);
    },
    closePopup() {
      this.popup = false;
      const path = this.$route.path;
      const context = this.$route.hash.split("?")[0];
      let routePath = path + context;
      history.pushState({}, null, routePath);
      this.$ga.page(routePath);
      setTimeout(() => {
        // console.log("null");
        this.pdf = null;
        this.video = null;
        this.petition = null;
      }, 250);
    },
    resize() {
      this.counter++;
    }
  },
  mounted() {
    window.$v = this;
    this.$hub.$on("openImagePopup", this.openImagePopup);
    this.$hub.$on("openPdf", this.openPdfPopup);
    window.addEventListener("resize", this.resize);
  }
};
</script>

<style lang="scss" scoped>
#popup-title {
  word-break: normal;
}
</style>
